<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  .form-check-input {
    margin-bottom: 5px;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import CheckHeader from "@/components/check-header";
  import printCardHeader from "./printCard-header";
  import {
    editZkz,
    getZkzInfo,
    clearZkzkTime
  } from "@/api/printCard/printCard.js"
  /**
   * 成绩库导入--发布时间
   */
  export default {
    components: {
      Layout,
      CheckHeader,
      printCardHeader,


    },
    data() {
      return {
        title: "成绩库导入 ",
        title2: "",
        items: [{
            text: "用户首页",
            href: '/admin'
          },
          {
            text: "控制面板",
            active: true
          }
        ],
        sid: "",
        addForm: {
          sfyxwdl:"1"
        },
        pickerOptions_start: "",
        pickerOptions_end: "",
      };
    },
    methods: {
      changeDate() {
        let _this = this
        let date_start = new Date(_this.addForm.kfrq).getTime();
        let date_end = new Date(_this.addForm.jsrq).getTime();
        _this.pickerOptions_start = {
          disabledDate: (time) => {
            if (date_end != "") {
              return time.getTime() > date_end;
            } else {
              return time.getTime() > Date.now();
            }
          },
        };
        _this.pickerOptions_end = {
          disabledDate: (time) => {

            return time.getTime() < date_start;
          },

        }
      },

      // 获取详情
      getDeatails(sid) {
        getZkzInfo(sid).then(res => {
          if (res.status) {
            this.addForm = res.data
            this.title2 = res.data.ksmc ? res.data.ksmc : ""
          }
        })
      },
      // 修改成绩库
      editItem() {
        let formDate = JSON.parse(JSON.stringify(this.addForm))
        editZkz({
          ...formDate,
          sid: this.sid
        }).then(res => {
          if (res.status) {
            this.$notify({
              type: 'success',
              message: res.message,
              duration: 3000,
            })
            this.$refs.header.getUrl('/admin/printCardStyle')

          }
        })
      },
      //清空时间
      clearTime(text) {

        clearZkzkTime(this.sid, text).then(res => {
          if (res.status) {
            this.$message({
              type: "success",
              message: "当前时间清空成功！"
            })
            this.getDeatails(this.sid)
          }
        })
      }
    },
    mounted() {
      if (this.sid) {
        this.getDeatails(this.sid)
      }
    },
    created() {
      this.sid = this.$route.query["sid"] ? this.$route.query["sid"] : "";

    }
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :title2="title2" :items="items" />
    <printCardHeader ref="header" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 470px;">
          <div class="card-body">
            <div class="outerCotent mt-3 ">
              <input type="hidden" class=" msgValInput w-30" value="" name="sid">
              <div class="msgContent flexList fs-xs">
                <div class="flexList w-100 msgLabel ">
                  <div class="msgName ">是否允许打印准考证</div>
                  <div class="msgVal selectShow  flexList w-33">
                    <div class="frame-wrap">
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input" id="value1" value="1" v-model="addForm.sfyxdy">
                        <label class="custom-control-label" style="line-height:19px" for="value1">允许</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input " id="value2" value="0"
                          v-model="addForm.sfyxdy">
                        <label class="custom-control-label" style="line-height:19px" for="value2">不允许</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flexList w-100 msgLabel ">
                  <div class="msgName ">是否允许未登录打印准考证</div>
                  <div class="msgVal selectShow  flexList w-33">
                    <div class="frame-wrap">
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input" id="val1" value="1" v-model="addForm.sfyxwdl">
                        <label class="custom-control-label" style="line-height:19px" for="val1">允许</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input " id="val2" value="0"
                               v-model="addForm.sfyxwdl">
                        <label class="custom-control-label" style="line-height:19px" for="val2">不允许</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="w-100">
                  <div class="flexList w-100 msgLabel">
                    <div class="msgName ">准考证打印开始时间</div>
                    <div class="msgVal  flexList">
                      <!-- placeholder="选择日期时间" @change="addForm.kfrq=formatDateDetails(new Date($event))" -->
                      <el-date-picker v-model="addForm.kfrq" type="datetime" class="  h30 w-30" size="small"
                        placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions_start"
                        @input="changeDate">
                      </el-date-picker>
                      <el-button @click="clearTime('kfrq')" type="info" size="mini" class="ml-2">清空时间</el-button>
                    </div>
                  </div>
                  <div class="flexList w-100 msgLabel">
                    <div class="msgName ">准考证打印结束时间</div>
                    <div class="msgVal  flexList">
                      <el-date-picker v-model="addForm.jsrq" type="datetime" class="  h30 w-30" size="small"
                        placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions_end"
                        @input="changeDate">
                      </el-date-picker>
                      <el-button @click="clearTime('jsrq')" type="info" size="mini" class="ml-2">清空时间</el-button>

                    </div>
                  </div>
                </div>




                <div class="flexList w-100 msgLabel" style="height: 60px;">
                  <div class="msgName"></div>
                  <div class="msgVal flexList">
                    <button class="btn btn-info h35 mr-2 sub-btn" @click="editItem">确定并进入下一步</button>
                    <a href="javascript:history.back()"><button class="btn btn-secondary h35 mr-2 ">返回上一步</button></a>
                  </div>
                </div>
              </div>

            </div>



          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->

    <!-- 弹窗结束 -->
  </Layout>
</template>
